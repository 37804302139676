var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"filter-box my-3"},[_c('v-row',{staticClass:"d-flex flex-row justify-space-between align-center"},[_c('h3',[_vm._v(_vm._s(_vm.$t('TIMETRACKING.FILTER.FILTER')))]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"on-background-variant","icon":"","text":""},on:{"click":_vm.onReloadClicked}},on),[_c('v-icon',[_vm._v("mdi-reload")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('TIMETRACKING.FILTER.RELOAD')))])])],1),_c('v-row',{staticClass:"d-flex align-center topic"},[_c('h4',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t('GENERAL.OBJECT_MANAGER')))])]),_c('v-row',{staticClass:"d-flex align-center mb-3"},[(_vm.$userRoleHandler.hasSomePermissions([_vm.Permission.CUSTOMER_CREATE, _vm.Permission.CUSTOMER_UPDATE_OWN]))?_c('RJAutocomplete',{attrs:{"itemText":_vm.getUserFullName,"items":_vm.manager,"clearable":true,"multiple":true,"returnObject":true},on:{"change":_vm.updateMarkerObjects},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"d-flex justify-center align-center my-0 py-0"},[_c('filter-radio-component')],1),_c('v-divider',{staticClass:"my-2"})]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"overflow-handle"},[_vm._v(" "+_vm._s(item.fullName)+" "),_c('p',{staticClass:"email-selection"},[_vm._v(_vm._s(item.email))])])]}}],null,false,3796269044),model:{value:(_vm.selectedManager),callback:function ($$v) {_vm.selectedManager=$$v},expression:"selectedManager"}}):_vm._e()],1),_c('v-row',{staticClass:"d-flex align-center topic"},[_c('h4',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t('GENERAL.EMPLOYEES')))])]),_c('v-row',{staticClass:"d-flex align-center mb-3"},[_c('RJAutocomplete',{attrs:{"itemText":_vm.getUserFullName,"items":_vm.users(true),"clearable":true,"multiple":true,"returnObject":true},on:{"change":_vm.updateMarkerObjects},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"d-flex justify-center align-center my-0 py-0"},[_c('filter-radio-component',{on:{"change":function($event){return _vm.updateMarkerObjects()}},model:{value:(_vm.show.users),callback:function ($$v) {_vm.$set(_vm.show, "users", $$v)},expression:"show.users"}})],1),_c('v-divider',{staticClass:"my-2"})]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"overflow-handle"},[_vm._v(" "+_vm._s(item.fullName)+" "),_c('p',{staticClass:"email-selection"},[_vm._v(_vm._s(item.email))])])]}}]),model:{value:(_vm.selectedUsers),callback:function ($$v) {_vm.selectedUsers=$$v},expression:"selectedUsers"}})],1),_c('v-row',{staticClass:"d-flex align-center topic"},[_c('h4',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t('GENERAL.CUSTOMER')))])]),_c('v-row',{staticClass:"d-flex align-center mb-3"},[_c('RJAutocomplete',{attrs:{"items":_vm.customers,"clearable":true,"itemText":'name',"multiple":true,"returnObject":true},on:{"change":function($event){return _vm.updateMarkerObjects()}},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"d-flex justify-space-around align-center my-0 py-0"},[_c('filter-radio-component',{on:{"change":function($event){return _vm.updateMarkerObjects()}},model:{value:(_vm.show.customers),callback:function ($$v) {_vm.$set(_vm.show, "customers", $$v)},expression:"show.customers"}})],1),_c('v-divider',{staticClass:"my-2"})]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"overflow-handle"},[_vm._v(_vm._s(item.name))])]}}]),model:{value:(_vm.selectedCustomers),callback:function ($$v) {_vm.selectedCustomers=$$v},expression:"selectedCustomers"}})],1),_c('v-row',{staticClass:"d-flex align-center topic"},[_c('h4',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t('GENERAL.CLEANING_LOCATIONS')))])]),_c('v-row',{staticClass:"d-flex align-center mb-3"},[_c('RJAutocomplete',{attrs:{"disabled":_vm.locations(true).length <= 0,"items":_vm.filteredLocations(true),"clearable":true,"itemText":'name',"multiple":true,"returnObject":true},on:{"change":function($event){return _vm.updateMarkerObjects()}},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"d-flex justify-center align-center my-0 py-0"},[_c('filter-radio-component',{on:{"change":function($event){return _vm.updateMarkerObjects()}},model:{value:(_vm.show.locations),callback:function ($$v) {_vm.$set(_vm.show, "locations", $$v)},expression:"show.locations"}})],1),_c('v-divider',{staticClass:"my-2"})]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"overflow-handle"},[_vm._v(_vm._s(item.name))])]}}]),model:{value:(_vm.selectedLocations),callback:function ($$v) {_vm.selectedLocations=$$v},expression:"selectedLocations"}})],1),_c('v-row',{staticClass:"mb-0"},[_c('span',{staticClass:"focus-text"},[_vm._v(" "+_vm._s(_vm.$t('MAP.AUTOFOCUS.AUTOFOCUS'))+": "+_vm._s(_vm.autofocus ? _vm.$t('MAP.AUTOFOCUS.ON') : _vm.$t('MAP.AUTOFOCUS.OFF')))])]),_c('v-row',[_c('v-btn',{staticClass:"primary map-button",attrs:{"elevation":"0"},on:{"click":_vm.triggerCentralization}},[_vm._v(" "+_vm._s(_vm.$t('MAP.CENTRALIZE'))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }